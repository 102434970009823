import { createContext, useContext, useEffect, useState } from "react"
import { initializeApp } from "firebase/app"
import { getFirestore, doc, setDoc, collection as firebaseCollection, getDoc } from "firebase/firestore"
import axios from "axios"
const superFanContext = createContext()

export const SuperFanProvider = ({ children, value }) => {
  const { collection, firebaseConfig, superfan } = value

  let uid = localStorage.getItem("uid")

  const [preScore, setPreScore] = useState(null)
  const [clicked, setClicked] = useState(null)
  const [isRedeem, setIsRedeem] = useState(false)
  const [info, setInfo] = useState({
    uid: uid ?? uuidv4(),
    createTime: Date.now(),
    _name: null,
  })

  localStorage.setItem("uid", info.uid)

  const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)

  const init = (_preTimeSpen = 0, _replay = 1) => {
    let count20 = 20
    let current = new Date().getTime()
    let timeSpend = _preTimeSpen
    if (!_replay) _replay = 1

    if (_replay === 1) {
      setDoc(
        doc(db, collection, info.uid),
        {
          createTime: new Date(),
          uid: info.uid,
          oparationSystem: navigator.appVersion,
          replay: _replay,
        },
        {
          merge: true,
        },
      )
    } else {
      setDoc(
        doc(db, collection, info.uid),
        {
          uid: info.uid,
          oparationSystem: navigator.appVersion,
          replay: _replay,
        },
        {
          merge: true,
        },
      )
    }
    const countDown = () => {
      // check if 1 second has passed
      if (new Date().getTime() - current > 1000) {
        current = new Date().getTime()
        // hander time spend
        if (count20 <= 0) {
          count20 = 20

          timeSpend += count20
          setDoc(
            doc(db, value.collection, info.uid),
            {
              timeSpend,
            },
            { merge: true },
          )
        } else {
          count20--
        }
      }
      requestAnimationFrame(countDown)
    }

    countDown()
  }

  const trackBtn = (type) => {
    if (info.uid) {
      let count = 0
      if (clicked) {
        count = parseInt(clicked[type]?.count ?? 0)
      }
      count++
      let data = {
        clicked: {
          [type]: {
            time: new Date(),
            count: count,
          },
        },
      }
      setDoc(doc(db, collection, info.uid), data, {
        merge: true,
      })
    }
  }

  const customType = (type, value) => {
    if (info.uid) {
      let data = {
        [type]: value,
      }
      setDoc(doc(db, collection, info.uid), data, {
        merge: true,
      })
    }
  }

  const submit = (props) => {
    if (info.uid) {
      setDoc(
        doc(db, superfan, info.uid),
        {
          ...props,
          createTime: Date(),
          uid: info.uid,
        },
        {
          merge: true,
        },
      ).then((e) => {
        console.log(e)
      })
    }
  }

  const getCheckHistory = async () => {
    if (uid) {
      const docRef = doc(db, collection, uid)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        if (docSnap.data()?.score) {
          setPreScore(docSnap.data()?.score)
        }

        if (docSnap.data()["redeemTime"]) {
          setIsRedeem(true)
        }
        if (docSnap.data()) {
          setClicked(docSnap.data()?.clicked)
          setInfo({
            ...info,
            _name: docSnap.data()?.name ?? null,
          })
          init(docSnap.data()?.timeSpend ?? 0, docSnap.data()?.replay + 1 ?? 1)
        }

        console.log("Document data:", docSnap.data())
      }
    } else {
      init()
    }

    // setTimeout(() => {
    //   axios.get("http://www.geoplugin.net/json.gp").then((res) => {
    //     let _data = res.data

    //     delete _data.geoplugin_credit

    //     setDoc(doc(db, collection, info.uid), res.data, {
    //       merge: true,
    //     })
    //   })
    // }, 1000)
  }

  useEffect(() => {
    getCheckHistory()
  }, [])

  return (
    <superFanContext.Provider
      value={{
        info,
        trackBtn,
        submit,
        customType,
        preScore,
        collection,
        isRedeem,
      }}>
      {children}
    </superFanContext.Provider>
  )
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const useSuperFan = () => useContext(superFanContext)
